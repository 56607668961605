* {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    text-decoration: none;
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
ol,
ul {
    list-style: none;
}
del {
    text-decoration: line-through;
}
body {
    font-family: "poppins_regular";
    font-size: 17px;
    color: #1f3347;
    overflow-x: hidden;
    min-width: 320px;
    font-weight: 500;
}
body::-webkit-scrollbar {
    display: none;
}
input,
textarea,
select {
    font-family: "poppins_regular";
    font-weight: 500;
}
a {
    color: #000;
    cursor: pointer;
}
img {
    display: block;
    width: 100%;
}
p {
    line-height: 1.5em;
    font-size: 16px;
    font-family: "poppins_regular";
    color: #999;
}

.wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: 1350px;
}
body::-webkit-scrollbar {
    display: none;
}

.learn-button a {
    background-color: #5bba64;
    display: inline-block;
    font-size: 14px;
    padding: 14px 30px;
    border-radius: 30px;
    color: #fff;
}

/* COLORS */
.text-primary{
    color: rgb(232 22 49) !important;
}
.text-secondry{
    color:#00258d !important
}
/* //COLORS */
.accordion-ukx{
    border-radius: 24px;
    margin-bottom: 22px;
    padding: 20px;
    margin-right: 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 13px 4px 64px;
}
/* FONTS */

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    /* padding-top:10px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 10px;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #00258d;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 3px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

@font-face {
    font-family: "poppins_bold";
    src: url("../fonts//Poppins-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "poppins_medium";
    src: url("../fonts/Poppins-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "poppins_light";
    src: url("../fonts//Poppins-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "poppins_regular";
    src: url("../fonts//Poppins-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "poppins_semibold";
    src: url("../fonts/Poppins-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* SLIDER */

.slick-slider .slick-list {
    max-height: 380px !important;
    max-width: 700px !important;
}
.slick-slide {
    width: 338px !important;
}

@media all and (max-width: 768px) {
    .slick-slide {
        width: 318px !important;
    }
}
@media all and (max-width: 480px) {
    .slick-slide {
        width: 280px !important;
    }
}

.pagination {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.pagination .pagination_break_li,
.pagination .pagination__li {
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    cursor: pointer;
}
.pagination .pagination__li a,
.pagination .pagination_break_a {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #333;
    width: 35px;
    height: 35px;
    display: block;
}
.pagination .pagination_li_active {
    border-radius: 8px;
    background: #6347f9;
    border: 1px solid #d3d3d3;
}
.pagination .pagination_li_active a {
    color: #fff;
}

.pagination .pagination_next_li,
.pagination .pagination_previous_li {
}
.pagination .pagination_previousli .paginationprevious_a,
.pagination .pagination_nextli .paginationnext_a {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #333;
    width: 35px;
    height: 35px;
    display: block;
    cursor: pointer;
}
.pagination .pagination_disabled_btns {
    opacity: 0.4;
}
.pagination_next_a {
    color: #6347f9;
    border: none !important ;
}
